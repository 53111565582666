import { BasicsClass } from '@/common/BasicsClass';



export class Commodity extends BasicsClass {
  //获取商品分类列表
  public getCategoryList(form: any, page: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/category/page', { ...form, ...page }, false, false, cb);
  }
  //获取一级商品分类列表
  public getListAllOne(cb: Function) {
    this.BasicGet('/animal/web/ordering/category/listAllOne', {}, false, false, cb);
  }
  //获取所有商品分类列表
  public getListTree(cb: Function) {
    this.BasicGet('/animal/web/ordering/category/listTree', {}, false, false, cb);
  }
  //新增商品分类
  public addCategory(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/category/save', {...form}, false, false, true, cb);
  }
  //修改商品分类
  public updateCategory(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/category/update', {...form}, false, false, true, cb);
  }
  //删除商品分类
  public deleteCategory(id: any, cb: any): void {
    this.BasicGet('/animal/web/ordering/category/delete', {id}, false, false, cb);
  }
  

}

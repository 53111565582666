






































































































































































































































































































































































































import { Watch, Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";

// 导入可用的插件
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/help";
import "tinymce/plugins/wordcount";

import InputComonentVue from "@/components/BasicConponents/InputConponentsVue.vue";
import { Column } from "@/common/Views/system/column/column";

import { Commodity } from "@/common/Views/BeefOrdering/CommodityClassification/CommodityClassification";
import { productList } from "@/common/Views/BeefOrdering/productList/productList";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
@Component({
    components: {
        InputComonentVue,
        editor: Editor,
        PageBox,
    },
})
export default class AddClassification extends Vue {
    private form: any = {
        goodsName: "",
        categoryOneId: "",
        categoryTwoId: "",
        brandId: "",
        videoName: "",
        labelNumber: "",
        mainImgs: "",
        detailImgs: "",
        video: "",
        videoFirstFrame: "",
        description: "",
        status: 0,
        skuList: [],
        breed: "",
        id: "",
    };
    private specs: any = [];
    private skuVal = "";
    private skuDialogVisible = false;
    private xianBeef = "";
    private skuList = [];
    private kgList = [];
    private lList = [];
    private updateType = false;
    private rules: any = {
        goodsName: [
            { required: true, message: "请输入商品名称", trigger: "change" },
        ],
        brandId: [{ required: true, message: "请选择品牌", trigger: "change" }],
        categoryTwoId: [
            {
                required: true,
                message: "请选分类",
                trigger: "change",
            },
        ],
        mainImgs: [
            { required: true, message: "请上传商品图片", trigger: "change" },
        ],
        breed: [
            {
                required: true,
                message: "请选择品种",
                trigger: "change",
            },
        ],
    };
    private tablePage = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private isProduct = 1;
    private switchTab(type: number) {
        this.isProduct = type;
    }
    private kgSetList: any = [];
    private lSetList: any = [];
    private CheckedType = false;

    private handleCheckedChange(type: any) {
        if (type == "kg") {
            this.lSetList = [];
            this.CheckedType = false;
        } else {
            this.kgSetList = [];
            this.CheckedType = true;
        }
    }
    private cancelBtn() {
        this.lSetList = [];
        this.kgSetList = [];
        this.skuDialogVisible = false;
    }

    private confirmBtn() {
        console.log(this.kgSetList, 460);
        console.log(this.lSetList, 461);
        let skuListExp:any = []
        const deepCopy: DeepLoop = new DeepLoop();
        let skuListExp1:any = deepCopy.deepCopy(this.form.skuList);
        let kgSetListexp = deepCopy.deepCopy(this.kgSetList);
        let lSetListexp = deepCopy.deepCopy(this.lSetList);
        // this.form.skuList = [];
        console.log(this.form.skuList, 513);
        this.skuDialogVisible = false;
        // 去重 kgSetList，保留原始元素，删除后来重复的、
        if (kgSetListexp.length > 0) {
            kgSetListexp = kgSetListexp.filter(
                (item: any, index: any, self: any) =>
                    index === self.findIndex((t) => t.skuVal === item.skuVal)
            );
        }
        if (lSetListexp.length > 0) {
            lSetListexp = lSetListexp.filter(
                (item: any, index: any, self: any) =>
                    index === self.findIndex((t) => t.skuVall === item.skuVall)
            );
        }
        // 去重 lSetList

        console.log(kgSetListexp, 535);
        console.log(lSetListexp, 536);
        skuListExp = [...this.combineArrays(kgSetListexp, lSetListexp)]
        
        skuListExp1 = this.updateSkuListExp1(skuListExp, skuListExp1)
        console.log(skuListExp, 464);
        console.log(skuListExp1, 465);
        this.form.skuList = deepCopy.deepCopy(skuListExp1);
        
    }
    private updateSkuListExp1(skuListExp:any, skuListExp1:any) {
    // 创建一个Map来保存skuListExp1中的数据，方便快速查找
    let skuMap = new Map();
    skuListExp1.forEach((item:any, index:any) => {
        const key = `${item.weight}_${item.volume}`;
        skuMap.set(key, { ...item, index });
    });

    // 创建一个新的数组用于保存更新后的skuListExp1
    let newSkuListExp1:any= [];

    // 遍历skuListExp，将对应的weight和volume匹配的元素加入newSkuListExp1
    skuListExp.forEach((item:any) => {
        const key = `${item.weight}_${item.volume}`;
        if (skuMap.has(key)) {
            // weight和volume相同，保留skuListExp1中的数据
            newSkuListExp1.push(skuMap.get(key));
            skuMap.delete(key);  // 删除已处理的项
        } else {
            // weight和volume不同，新增item
            newSkuListExp1.push(item);
        }
    });

    // skuListExp1中剩下的没有在skuListExp中的数据应该被删除，不再加入newSkuListExp1
    // (即没有在skuListExp中匹配到的skuListExp1项)

    return newSkuListExp1;
}
    private updateInput(type: any, row: any, index: any) {}
    private splitNumberAndUnit(str: any) {
        // 正则表达式匹配数字和单位
        const regex = /^(\d+(?:\.\d+)?)\s*(\w+)$/;

        // 使用正则表达式匹配字符串
        const match = str.match(regex);

        if (match) {
            // 如果匹配成功，返回一个包含数字和单位的对象
            return {
                specs: parseFloat(match[1]),
                unit: match[2],
            };
        } else {
            // 如果匹配失败，返回null或者你想要的其他值
            return null;
        }
    }
    private setspecification() {
        //设置规格
        this.kgSetList = [];
        this.lSetList = [];
        this.form.skuList.forEach((item:any) => {
            if (item.weight) {
                item.skuVal = item.weight.toString();
                this.kgSetList.push(item);
            }
            if (item.volume) {
                item.skuVall = item.volume.toString();
                this.lSetList.push(item);
            }
        })
        this.skuDialogVisible = true;
    }
    private handleInput(row: any, field: any) {
        // 只允许输入数字和小数点，并且限制长度在20个字符以内
        let value = row[field];
        if (field == "sort") {
            // 只允许输入数字，不允许小数点
            value = value.replace(/[^0-9]/g, "");
        } else {
            // 允许输入数字和一个小数点
            value = value.replace(/[^0-9.]/g, "");
            // 确保只有一个小数点
            if (value.split(".").length > 2) {
                value = value.replace(/\.+$/, "");
            }
            //确保小数点后只有两位
            if (value.split(".").length > 1) {
                value = value.replace(/(\.\d{2}).+$/, "$1");
            }
        }

        // 限制长度为20个字符
        if (value.length > 10) {
            value = value.slice(0, 10);
        }

        row[field] = value;
        if (field == "unitPrice") {
            row.price = (row.unitPrice * row.weight).toFixed(2);
        }
        if (field == "price") {
            row.unitPrice = (row.price / row.weight).toFixed(2);
        }
    }
    private breedList: any = [];
    //事件
    private SaveData(type: any) {
        //保存
        console.log(this.form, 498);
        console.log(this.videoList, 802);
        if (Array.isArray(this.imgList) && this.imgList.length > 0) {
            this.form.mainImgs = this.imgList
                .map((item: any) => item.url)
                .join(",");
        }
        if (Array.isArray(this.videoList) && this.videoList.length > 0) {
            this.form.video = this.videoList[0].url;
            this.form.videoName = this.videoList[0].name;
        } else {
            this.form.video = "";
            this.form.videoName = "";
        }
        console.log(this.form.videoFirstFrame, "this.videoFirstFrame580");
        this.$refs.form.validate((flag: boolean) => {
            if (flag) {
                console.log(this.form, 565);
                let formObj = JSON.parse(JSON.stringify(this.form));
                if (formObj.parentId == 0) {
                    formObj.parentId = "";
                }
                console.log(formObj.skuList, 569);
                if (formObj.skuList && formObj.skuList.length > 0) {
                    const validatePrice = (
                        price: any,
                        message: string
                    ): boolean => {
                        if (price === "" || price == null || price == 0) {
                            this.$message({
                                type: "warning",
                                message: message,
                            });
                            return false;
                        }
                        return true;
                    };

                    const processPrice = (price: any): any => {
                        if (price !== "" && price != null && price != 0) {
                            return price * 100;
                        }
                        return "";
                    };

                    for (const item of formObj.skuList) {
                        // 检查价格和单价是否有效（不能为空或0）
                        if (
                            !validatePrice(
                                item.price,
                                "请设置单价"
                            ) ||
                            !validatePrice(
                                item.unitPrice,
                                "请设置售价"
                            )
                        ) {
                            return; // 如果未通过验证，则直接返回，终止循环
                        }

                        // 处理价格
                        item.price = processPrice(item.price);

                        // 处理折扣价格
                        item.discountedPrice = processPrice(
                            item.discountedPrice
                        );

                        // 处理单价
                        item.unitPrice = processPrice(item.unitPrice);
                    }
                } else {
                    this.$message({
                        type: "warning",
                        message: "请设置规格",
                    });
                    return;
                }
                if (!formObj.id) {
                    if (type == "list") {
                        this.$confirm("是否保存并上架？", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        }).then(() => {
                            formObj.status = 3;
                            this.addGoods(formObj, type);
                        });
                    } else {
                        this.addGoods(formObj, type);
                    }
                } else {
                    if (type == "list") {
                        this.$confirm("是否保存并上架？", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        }).then(() => {
                            formObj.status = 3;
                            this.updateGoods(formObj, type);
                        });
                    } else {
                        this.updateGoods(formObj, type);
                    }
                }

                // if (this.updateType) {
                //     this.updateMerchant(this.form);
                // } else {

                // }
            }
        });
    }

    //emit事件
    private SetGoodsNameValue(val: string) {
        //商户名称
        this.form.goodsName = val;
    }
    private SetlabelNumberValue(val: string) {
        //商户名称
        this.form.labelNumber = val;
    }
    private SetKeyValue(val: any[]) {
        //key
        this.form.sort = val;
    }
    private addGoods(form: any, type: any) {
        //新增
        console.log(form, "660");
        new productList().addGoods(form, (res: any) => {
            if (type == "list") {
                this.$message({
                    type: "success",
                    message: "已提交给平台进行上架审核",
                });
                setTimeout(() => {
                    tinymce.activeEditor.setContent("");
                    this.$router.push({
                        path: "/productList",
                    });
                }, 3000);
            } else {
                if (type == "draft") {
                } else {
                    this.ClearFormData();
                }
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
            }
        });
    }
    private updateGoods(form: any, type: any) {
        //修改
        console.log(form, "687");
        new productList().updateGoods(form, (res: any) => {
            if (type == "list") {
                this.$message({
                    type: "success",
                    message: "已提交给平台进行上架审核",
                });
                setTimeout(() => {
                    tinymce.activeEditor.setContent("");
                    this.$router.push({
                        path: "/productList",
                    });
                }, 3000);
            } else {
                if (type == "draft") {
                } else {
                    this.ClearFormData();
                }
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
            }
        });
    }
    private ClearFormData() {
        //清楚Form数据
        this.form = {
            goodsName: "",
            categoryOneId: "",
            categoryTwoId: "",
            brandId: "",
            labelNumber: "",
            mainImgs: "",
            detailImgs: "",
            video: "",
            videoFirstFrame: "",
            description: "",
            status: 0,
            skuList: [],
            id: "",
        };
        this.imgList = [];
        this.videoList = [];
        this.treeSelect = [];
        this.isProduct = 1;
        this.kgSetList = [];
        this.lSetList = [];
        this.backupSkuList = [];
    }

    private ClearCheck() {
        //清除校验
        this.$refs.form.clearValidate();
    }
    private listAllOne = [];
    private wu = {
        id: 0,
        categoryName: "无",
    };
    activated() {}
    private uidddd = 0;
    private generateUniqueId() {
        this.uidddd++;
        return `${Date.now()}_${this.uidddd}`;
    }
    private status = false;
    private imgExample = {
        name: "",
        url: "",
        uid: "",
    };
    private backupSkuList = [];
    private getProductInfo(id: any) {
        new productList().getGoodsInfo(id, (res: any) => {
            console.log(res.data, 326);
            let data = res.data;
            data.skuList.forEach((item: any) => {
                if (item.price) {
                    item.price = (item.price / 100).toFixed(2);
                }
                if (item.discountedPrice) {
                    item.discountedPrice = (item.discountedPrice / 100).toFixed(
                        2
                    );
                }
                if (item.promotionalPrice) {
                    item.price = (item.price / 100).toFixed(2);
                }
                if (item.unitPrice) {
                    item.unitPrice = (item.unitPrice / 100).toFixed(2);
                }
                if (item.weight) {
                    
                }
            });
            this.form = {
                goodsName: data.goodsName,
                categoryOneId: data.categoryOneId,
                categoryTwoId: data.categoryTwoId,
                brandId: data.brandId,
                labelNumber: data.labelNumber,
                mainImgs: data.mainImgs,
                detailImgs: data.detailImgs,
                video: data.video,
                videoName: data.videoName,
                videoFirstFrame: data.videoFirstFrame
                    ? data.videoFirstFrame
                    : "",
                description: data.description,
                status: data.status,
                skuList: data.skuList,
                id: data.id,
                breed: data.breed.toString(),
            };
            console.log(this.form,917);
            data.mainImgs.split(",").forEach((item: any) => {
                let imgObj = { ...this.imgExample };
                imgObj.name = "mainImgs";
                imgObj.url = item;
                imgObj.uid = this.generateUniqueId();
                this.imgList.push(imgObj);
            });
            if (data.video) {
                this.videoList[0] = {
                    name: data.videoName,
                    url: data.video,
                    uid: this.generateUniqueId(),
                    status: "success",
                };
            }

            this.videoList = [...this.videoList];
            console.log(this.imgList, "this.imgList");
            console.log(this.videoList, "this.videoList");
            this.isProduct = 1;
            this.backupSkuList = [...data.skuList];
            //从skuList中获取规格,并分别存入kgSetList和lSetList,并且需要去重
            data.skuList.forEach((item: any) => {
                if (item.weight) {
                    item.skuVal = item.weight.toString();
                    this.kgSetList.push(item);
                    this.specs.push("kg");
                    console.log(this.kgSetList, "941++++");
                }
                if (item.volume) {
                    item.skuVall = item.volume.toString();
                    this.lSetList.push(item);
                    this.specs.push("L");
                    console.log(this.lSetList, "947++++");
                }
            });
            // 去重 kgSetList
            this.kgSetList = [...this.kgSetList.filter(
                (item: any, index: any, self: any) =>
                    index === self.findIndex((t) => t.weight === item.weight)
            )];
            // 去重 lSetList
            this.lSetList = [...this.lSetList.filter(
                (item: any, index: any, self: any) =>
                    index === self.findIndex((t) => t.volume === item.volume)
            )];
            console.log(this.kgSetList, "958++++");
            console.log(this.lSetList, "959++++");
            // specs 的去重保持不变
            this.specs = Array.from(new Set(this.specs));

            this.treeSelect = [data.categoryOneId, data.categoryTwoId];
            tinymce.activeEditor.setContent(data.description);
        });
    }
    private changestatusBoolean() {
        if (this.status == true) {
            this.form.status = 0;
        } else {
            this.form.status = 1;
        }
    }

    private dialogImageUrl = "";
    private imgDialogVisible = false;
    private file: any = {};
    private fileList: any = [];
    private imgList: any = [];

    private mounted() {
        tinymce.init({});
        let id: any = this.$route.query.id;
        console.log(id, 883);
        this.getListTree();
        this.getBrandList();
        this.getAllCowBreeds();
        this.ClearCheck();
        this.ClearFormData();
        if (id) {
            this.form.id = id;
            this.getProductInfo(this.form.id);
        }
    }
    private getAllCowBreeds(){
        new productList().getAllCowBreeds((res: any) => {
            console.log(res, 916);
            this.breedList = res.data;
            console.log(this.breedList, 961);
        });
    };
    private next() {
        if (this.form.categoryOneId == "") {
            this.$message({
                type: "warning",
                message: "请选择分类",
            });
            return;
        } else if (this.form.brandId == "") {
            this.$message({
                type: "warning",
                message: "请选择品牌",
            });
            return;
        } else if (this.form.goodsName == "") {
            this.$message({
                type: "warning",
                message: "请设置商品名称",
            });
            return;
        } else if (this.imgList.length == 0) {
            this.$message({
                type: "warning",
                message: "请上传商品轮播图",
            });
            return;
        } else if (
            this.form.categoryOneId == "1010882678699851776" &&
            this.form.breed == ""
        ) {
            this.$message({
                type: "warning",
                message: "请选择品种",
            });
            return;
        }
        console.log(this.form, "form916");
        this.isProduct = 2;
    }
    private handleRemove(e: any) {
        const that = this;
        console.log(e, "eeeee913");
        console.log(that.file, "that.file913");
        if (
            (that.file.raw && that.file.raw.type == "video/mp4") ||
            (e.url && e.url.includes("mp4"))
        ) {
            that.videoList = [];
        } else {
            this.imgList = this.imgList.filter(
                (item: any) => item.uid !== e.uid
            );
        }
    }

    private handlePictureCardPreview(file: any) {
        this.dialogImageUrl = file.url;
        this.imgDialogVisible = true;
    }
    private beforeVideoUpload(file: any) {
        console.log(file, 310);
        const videoMaxSize = 2 * 1024 * 1024;
    }
    private fileName = "";
    private handleSuccess(file: any) {
        console.log(file, 301);

        const imgMaxSize = 2 * 1024 * 1024;
        const videoMaxSize = 10 * 1024 * 1024;
        if (file.raw.type == "video/mp4") {
            if (file.raw.size > videoMaxSize) {
                this.$message({
                    type: "warning",
                    message: "视频大小不能超过10M",
                });
                return;
            } else {
                console.log(file.name, "file.name");
                this.fileName = file.name;
                this.file = file;
                this.getbase64();
            }
        } else {
            if (file.raw.size > imgMaxSize) {
                this.$message({
                    type: "warning",
                    message: "图片大小不能超过2M",
                });
                return;
            } else {
                this.file = file;
                this.getbase64();
            }
        }
    }
    private videoList = [];
    private imgRawList = [];
    private videoRawList = [];

    private getbase64(): void {
        const that = this;
        const reader = new FileReader();
        let data = reader.readAsDataURL(that.file.raw);
        reader.onloadend = () => {
            const strBase64 = (<string>reader.result).substring(0);
            if (that.file.raw.type != "video/mp4") {
                console.log("img");
                new Column().uploadImage((res: any) => {
                    console.log(res, 967);
                    let imgObj = { ...that.imgExample };
                    imgObj.name = "mainImg";
                    imgObj.url = res.data.url;
                    imgObj.uid = that.generateUniqueId();
                    that.imgList.push(imgObj);
                }, strBase64);
            } else {
                console.log("video/mp4");

                new Column().uploadFile(
                    "ordering",
                    (res: any) => {
                        console.log(res, 980);
                        console.log(that.fileName, 980);
                        that.getVideoCover(res.message);
                        that.videoList[0] = {
                            name: that.fileName,
                            url: res.message,
                            uid: that.generateUniqueId(),
                        };
                        console.log(that.videoList, 993);
                    },
                    that.file.raw
                );
            }
        };
    }
    private handleVideoSuccess(res: any) {
        console.log(res, 366);
        this.form.video = res.message;
    }
    private exceed(res: any) {
        this.$message({
            type: "warning",
            message: "图片上传到达限制，请删除后再进行添加",
        });
    }
    private videoexceed(res: any) {
        this.$message({
            type: "warning",
            message: "视频上传到达限制，请删除后再进行添加",
        });
    }
    //获取所有商品分类列表
    private TreeOption = [];
    private treeSelect = [];
    private getListTree() {
        new Commodity().getListTree((res: any) => {
            this.TreeOption = res.data;
            console.log(this.TreeOption, 282);
            if (this.form.categoryOneId && this.form.categoryTwoId) {
                this.treeSelect = [
                    this.form.categoryOneId,
                    this.form.categoryTwoId,
                ];
            }
        });
    }
    private BrandList = [];
    private getBrandList() {
        new productList().getBrandList((res: any) => {
            this.BrandList = res.data;
            console.log(this.BrandList, 326);
        });
    }
    private handleChange(e: any) {
        console.log(e);
        if (e[0]) {
            this.form.categoryOneId = e[0];
            this.TreeOption.forEach((item: any) => {
                if (item.id == e[0]) {
                    console.log(item, "item1106");
                    this.specs = item.specs.split(",");
                    console.log(this.specs, "this.specs");
                    this.xianBeef = item.categoryName;
                }
            });
        }
        if (e[1]) {
            this.form.categoryTwoId = e[1];
        }
    }
    private plugins =
        "lists link anchor code wordcount image table  searchreplace preview  media insertdatetime fullscreen autolink advlist";
    private toolbar = [
        "undo redo removeformat | bold italic underline strikethrough superscript subscript backcolor forecolor | numlist bullist | blocks | searchreplace fullscreen table",
        "fontfamily fontsize fontsizeselect fontsizeinput | alignleft aligncenter alignright alignjustify lineheight outdent indent | link unlink image | preview code",
    ];

    private init = {
        language_url: "/tinymce/langs/zh_CN.js", // 这里需要单独处理
        skin_url: "/tinymce/skins/ui/oxide",
        content_css: "/tinymce/skins/content/default/content.css",
        lineheight_formats: "0 1 1.2 1.5 1.6 1.8 2 2.4",
        language: "zh_CN",
        height: 800,
        width: 1000,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: true,
        menubar: true,
        fontsize_formats:
            "12px 14px 16px 18px 20px 22px 24px 26px 36px 48px 56px",
        font_size_input_default_unit: "px",
        //此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        //如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo: any, success: any, failure: any) => {
            console.log(blobInfo);
            const img = "data:image/jpeg;base64," + blobInfo.base64();
            new Column().uploadImage((res: any) => {
                console.log(res.data.url);
                success(res.data.url);
            }, img);
        },
        resize: true,
        promotion: false,
    };
    private extractNumbers(str: any) {
        return str.match(/\d+/g).map(Number);
    }
    private toDelete(row: any, index: any) {
        this.form.skuList.splice(index, 1);
        console.log(row);
        console.log(this.kgSetList);
        console.log(this.lSetList);
    }
    private getVideoCover(file: any) {
        let _this = this;
        let width = 0;
        let height = 0;
        // const  video = document.getElementById("myvideo"); // 获取视频对象
        const video = document.createElement("video"); // 也可以自己创建video
        video.src = file; // url地址 url跟 视频流是一样的

        // var canvas = document.getElementById('mycanvas') // 获取 canvas 对象
        var canvas = document.createElement("canvas"); // 获取 canvas 对象
        const ctx = canvas.getContext("2d"); // 绘制2d
        video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
        video.currentTime = 1; // 第一帧
        video.preload = "metadata";
        video.onloadedmetadata = function(evt) {
            URL.revokeObjectURL(file);
            width = video.videoWidth;
            height = video.videoHeight;
        };

        console.log(video.videoWidth, "video1131");
        video.oncanplay = () => {
            canvas.width = video.clientWidth ? video.clientWidth : width; // 获取视频宽度
            canvas.height = video.clientHeight ? video.clientHeight : height; //获取视频高度
            // 利用canvas对象方法绘图
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            // 转换成base64形式
            let videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
            let videoUrl = file;
            file = videoFirstimgsrc; // file的url储存封面图片+
            video.remove();
            canvas.remove();
            new Column().uploadImage((res: any) => {
                _this.form.videoFirstFrame = res.data.url;
            }, videoFirstimgsrc);
        };
    }
    private SetNumValue(val: string) {
        this.form.temp = val;
    }
    private skuExample = {
        specsCode: "",
        specs: 0,
        unitPrice: "",
        price: "",
        discountedPrice: "",
        promotionalPrice: "",
        sort: "",
    };
    private addSku(val: string) {
        if (val == "kg") {
            this.kgSetList.push({ skuVal: "" });
        } else {
            this.lSetList.push({ skuVall: "" });
        }
        console.log(this.kgSetList, 1131);
        console.log(this.lSetList, 1132);
    }
    private combineArrays(
        arr1: any[],
        arr2: any[],
        prop1 = "skuVal",
        prop2 = "skuVall"
    ) {
        // 如果 arr2 为空，则
        console.log(arr1, arr2, 1263);
        if (!arr2 || arr2.length === 0) {
            arr1.forEach((item) => {
                item.weight = item.skuVal;
                item.price = "";
                item.discountedPrice = "";
                item.promotionalPrice = "";
                item.unitPrice = "";
                item.sort = "";
            });
            return arr1;
        }
        if (!arr1 || arr1.length === 0) {
            arr2.forEach((item) => {
                item.volume = item.skuVall;
                item.price = "";
                item.discountedPrice = "";
                item.promotionalPrice = "";
                item.unitPrice = "";
                item.sort = "";
            });
            return arr2;
        }

        let result: any[] = [];

        for (let i = 0; i < arr1.length; i++) {
            for (let j = 0; j < arr2.length; j++) {
                if (arr1[i][prop1] == "" || arr2[j][prop2] == "") {
                    continue;
                }
                if (arr2[j].specsCode) {
                    if (arr1[i].specsCode) {
                        arr1[i].volume = arr2[j].skuVall;
                        result.push(arr1[i]);
                    } else {
                        result.push({
                            weight: arr1[i][prop1],
                            volume: arr2[j][prop2],
                            price: "",
                            discountedPrice: "",
                            promotionalPrice: "",
                            unitPrice: "",
                            sort: "",
                        });
                    }
                } else {
                    result.push({
                        weight: arr1[i][prop1],
                        volume: arr2[j][prop2],
                        price: "",
                        discountedPrice: "",
                        promotionalPrice: "",
                        unitPrice: "",
                        sort: "",
                    });
                    console.log(result, 1312);
                }
            }
        }

        return result;
    }
    private delsku(index: any, type: any) {
        if (type == "kg") {
            this.kgSetList.splice(index, 1);
        } else {
            this.lSetList.splice(index, 1);
        }
    }
    private HandlerSKUInput(val: any, index: any, type: any) {
        // 只允许输入数字和小数点，并且限制长度在20个字符以内
        let value = val;

        // 允许输入数字和一个小数点
        value = value.replace(/[^0-9.]/g, "");
        // 确保只有一个小数点
        if (value.split(".").length > 2) {
            value = value.replace(/\.+$/, "");
        }
        // 限制长度为5个字符
        if (value.length > 6) {
            value = value.slice(0, 6);
        }
        if (type == "kg") {
            this.kgSetList[index].skuVal = value;
        } else if (type == "L") {
            this.lSetList[index].skuVall = value;
        }
    }
    private BlurSKUChangeIput() {}
}

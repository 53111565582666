import { BasicsClass } from '@/common/BasicsClass';
import { fireEvent } from 'highcharts';



export class productList extends BasicsClass {
  //获取商品列表
  public getGoodsList(form: any, page: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/goods/page', { ...form, ...page }, false, false, cb);
  }
  //获取商品详情
  public getGoodsInfo(id: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/goods/getInfo', { id }, false, false, cb);
  }
  //查询商户所有品牌
  public getBrandList(cb: Function) {
    this.BasicGet('/animal/web/ordering/brand/listByMerchantId', {  }, false, false, cb);
  }
  //新增商品分类
  public addGoods(form: any, cb: any): void {
    this.BasicPost1('/animal/web/ordering/goods/save', { ...form }, false, false, true, cb);
  }
  //修改商品分类
  public updateGoods(form: any, cb: any): void {
    this.BasicPost1('/animal/web/ordering/goods/update', { ...form }, false, false, true, cb);
  }
  //删除商品分类
  public deleteGoods(id: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/goods/deleteSku', { id }, false, false, true, cb);
  }
  //批量上下架商品
  public updateGoodsStatus(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/goods/updateStatus', { ...form }, false, false, true, cb);
  }
  //获取品种列表
  public getAllCowBreeds(cb: Function) {
    this.BasicGet('/animal/web/confTypeInfo/getAllCowBreeds', {  }, false, false, cb);
  }

}
